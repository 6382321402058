import { Title } from '@solidjs/meta';
import { useLocation, useSearchParams } from '@solidjs/router';
import { AuthFlow } from '../../partials/auth/auth';

export default function Auth() {
	const [params] = useSearchParams<Record<string, string>>();
	const location = useLocation<{ fromCard?: boolean }>();
	const { redirect, redirectUrl, ...data } = params ?? {};

	return (
		<>
			<Title>Join or log in | Troon</Title>

			<AuthFlow
				isPage
				data={data}
				redirect={redirect ?? redirectUrl}
				descriptions={location.state?.fromCard ? cardDescriptions : undefined}
			/>
		</>
	);
}

const cardDescriptions = {
	'/auth/': () => <>Enter the email associated with your Troon Rewards Account to log in.</>,
};
